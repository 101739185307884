import queryString from 'query-string';

export const getRedirectUri = (
  search: string,
  defaultUri?: string,
): string | undefined => {
  const query = new URLSearchParams(search);
  return query.get('redirect_uri') || defaultUri;
};

export const parseQuery = (query: string) => {
  return queryString.parse(query);
};

export const stringifyQuery = (query: Record<string, unknown>) => {
  return queryString.stringify(query);
};
