import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-regular-svg-icons';
import classNames from 'classnames';

import { useSidebar } from './utils/useSidebar';
import styles from './Sidebar.module.scss';

export interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  className?: string;
}

/**
 * <SidebarCollapseButton> component
 */
export const SidebarCollapseButton: React.FunctionComponent<Props> = ({
  className,
  style,
  ...htmlElementProps
}) => {
  const { collapsed, toggleCollapse } = useSidebar();

  return (
    <button
      type="button"
      className={classNames(styles.button, 'collapse', className, {
        collapsed,
      })}
      aria-label={
        collapsed ? 'Maximizar barra lateral' : 'Minimizar barra lateral'
      }
      onClick={() => toggleCollapse()}
      {...htmlElementProps}
    >
      <FontAwesomeIcon icon={collapsed ? faChevronRight : faChevronLeft} />
    </button>
  );
};

export default SidebarCollapseButton;
