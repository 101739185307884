import {
  faCircleCheck,
  faCircleMinus,
  faCircleXmark,
} from '@fortawesome/pro-regular-svg-icons';
import { Icon, Text, useTheme } from '@quality24/design-system';
import classNames from 'classnames';
import React from 'react';
import { useMediaQuery } from 'react-responsive';

import styles from './PasswordChecker.module.scss';

export interface Props {
  valid: boolean;
  isSubmitting: boolean;
  description: string;
  examples?: string;
}

const icons = {
  valid: faCircleCheck,
  invalid: faCircleXmark,
  pending: faCircleMinus,
};

const PasswordChecker: React.FunctionComponent<Props> = ({
  valid,
  isSubmitting,
  description,
  examples,
}) => {
  const { theme } = useTheme();
  const isDesktop = useMediaQuery({
    query: `(min-width: ${theme.breakpoints.xxl})`,
  });

  const invalid = isSubmitting ? 'invalid' : 'pending';
  const status = valid ? 'valid' : invalid;
  const currentIcon = icons[status];

  const cls = classNames(
    styles.wrapper,
    `is-${status}`,
    'd-flex flex-row flex-items-center',
  );

  return (
    <div className={cls}>
      <Icon className="d-flex mr-2" icon={currentIcon} size="md" />
      <div>
        <Text
          className={!isDesktop ? 'd-block w-100' : ''}
          as="span"
          size="sm2"
        >
          {description}
        </Text>

        {examples && (
          <Text as="span" size="sm2">
            {examples}
          </Text>
        )}
      </div>
    </div>
  );
};

export default PasswordChecker;
