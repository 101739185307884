import { Theme } from '../@types/theme';

export const defaultTheme: Theme = {
  color: {
    // Primary colors
    primary: '#214e92',
    primaryDark: '#1A3E75',
    primaryLight: '#008eca',
    primaryLighter: '#a4d5ee',
    secondary: '#fd825f',
    secondaryLight: '#FEB8A4',
    secondaryLighter: '#FFEDE8',
    tertiary: '#653d85',
    tertiaryLight: '#A875D1',
    tertiaryLighter: '#C3B1D1',

    // Gray scale ?
    white: '#ffffff',
    whiteDark: '#edf0ed',
    grayLight1: '#f8f9fa',
    grayLight2: '#f7f7f7',
    grayLight3: '#f3f3f3',
    grayDark: '#a9a9a9',
    blackLight: '#343a40',
    black: '#1e1e1e',

    // Blue scale
    blue600: '#214e92',
    blue500: '#275BAA',
    blue400: '#2C68C3',
    blue300: '#3A77D2',
    blue200: '#5288D8',
    blue100: '#6B98DD',
    blue600_25: '#CCDCF3',
    blueLight: '#008ECA',

    // Purple scale
    purple600: '#653d85',
    purple500: '#7f4da8',
    purple400: '#9365b8',
    purple300: '#a781c5',
    purple200: '#bb9dd2',
    purple100: '#cfb9df',

    // Black scale
    gray600: '#1e1e1e',
    gray500: '#333333',
    gray400: '#474747',
    gray300: '#5c5c5c',
    gray200: '#707070',
    gray100: '#858585',

    // Green scale
    green500: '#399342',
    green400: '#4fba5a',
    green300: '#6cc675',
    green200: '#89d191',
    green100: '#a7ddad',
    green100_28: '#D3EDD5',

    // Yellow scale
    yellow500: '#cc9c00',
    yellow400: '#ffc60a',
    yellow300: '#ffcf33',
    yellow200: '#ffd95c',
    yellow100: '#ffe285',
    yellow100_28: '#FFF0C1',

    // Red scale
    red500: '#970f0c',
    red400: '#d01411',
    red300: '#ed201d',
    red200: '#f04542',
    red100: '#f36a68',
    red100_28: '#FBD0CF',

    // Indicators
    disabled: '#ADBDD6',
    success: '#399342',
    successLight: '#4fba5a',
    warning: '#cc9c00',
    warningLight: '#ffc60a',
    danger: '#970f0c',
    dangerLight: '#d01411',

    // Support
    backgroundColor: '#ffffff',
    borderColor: '#edf0ed',
    error: '#D01411',
    inherit: 'inherit',
    transparent: 'transparent',

    // chart colors
    chart: [
      'blue300',
      'purple300',
      'green300',
      'yellow300',
      'red300',
      'blue200',
      'purple200',
      'green200',
      'yellow200',
      'red200',
      'blue100',
      'purple100',
      'green100',
      'yellow100',
      'red100',
    ],

    // NPS Colors
    nps0: '#ee3534',
    nps1: '#f64646',
    nps2: '#fe654f',
    nps3: '#ff8943',
    nps4: '#fda83b',
    nps5: '#ffc120',
    nps6: '#ebbd0f',
    nps7: '#c0cb0f',
    nps8: '#9dcd06',
    nps9: '#79cc01',
    nps10: '#2db102',
  },
  typography: {
    fontFamily: "'Inter', sans-serif",
    fontSize: {
      desktop: {
        h1: {
          sm1: '1.25rem',
          sm2: '1.375rem',
          md: '1.75rem',
          lg1: '2rem',
          lg2: '2.875rem',
          xl: '3.625rem',
        },
        h2: {
          sm1: '0.875rem',
          sm2: '1rem',
          md: '1.625rem',
          lg1: '2rem',
          lg2: '2.25rem',
          xl: '2.875rem',
        },
        h3: {
          sm1: '0.875rem',
          sm2: '1rem',
          md: '1.125rem',
          lg1: '1.5rem',
          lg2: '2rem',
          xl: '2.5rem',
        },
        h4: {
          sm1: '0.875rem',
          sm2: '1rem',
          md: '1.125rem',
          lg1: '1.375rem',
          lg2: '1.75rem',
          xl: '2.125rem',
        },
        h5: {
          sm1: '0.875rem',
          sm2: '1rem',
          md: '1.125rem',
          lg1: '1.25rem',
          lg2: '1.5rem',
          xl: '1.75rem',
        },
        h6: {
          sm1: '0.875rem',
          sm2: '0.875rem',
          md: '0.875rem',
          lg1: '0.875rem',
          lg2: '1rem',
          xl: '1.125rem',
        },
        subtitle: {
          sm1: '0.625rem',
          sm2: '0.75rem',
          md: '0.875rem',
          lg1: '1rem',
          lg2: '1rem',
          xl: '1rem',
        },
        text: {
          sm1: '0.75rem',
          sm2: '0.875rem',
          md: '1rem',
          lg1: '1.125rem',
          lg2: '1.25rem',
          xl: '2rem',
        },
      },
      mobile: {
        h1: {
          sm1: '1rem',
          sm2: '1.125rem',
          md: '1.125rem',
          lg1: '1.375rem',
          lg2: '1.75rem',
          xl: '1.75rem',
        },
        h2: {
          sm1: '1rem',
          sm2: '1.125rem',
          md: '1.125rem',
          lg1: '1.25rem',
          lg2: '1.5rem',
          xl: '1.625rem',
        },
        h3: {
          sm1: '1rem',
          sm2: '1.125rem',
          md: '1.125rem',
          lg1: '1.25rem',
          lg2: '1.5rem',
          xl: '1.5rem',
        },
        h4: {
          sm1: '1rem',
          sm2: '1.125rem',
          md: '1.125rem',
          lg1: '1.25rem',
          lg2: '1.375rem',
          xl: '1.375rem',
        },
        h5: {
          sm1: '1rem',
          sm2: '1.125rem',
          md: '1.125rem',
          lg1: '1.25rem',
          lg2: '1.25rem',
          xl: '1.25rem',
        },
        h6: {
          sm1: '1rem',
          sm2: '1.125rem',
          md: '1.125rem',
          lg1: '1.125rem',
          lg2: '1.125rem',
          xl: '1.125rem',
        },
        subtitle: {
          sm1: '0.625rem',
          sm2: '0.75rem',
          md: '0.875rem',
          lg1: '0.875rem',
          lg2: '1rem',
          xl: '1rem',
        },
        text: {
          sm1: '0.75rem',
          sm2: '0.875rem',
          md: '1rem',
          lg1: '1.125rem',
          lg2: '1.25rem',
          xl: '1.25rem',
        },
      },
      tablet: {
        h1: {
          sm1: '1rem',
          sm2: '1.125rem',
          md: '1.375',
          lg1: '1.625',
          lg2: '2rem',
          xl: '2.5rem',
        },
        h2: {
          sm1: '1rem',
          sm2: '1.125rem',
          md: '1.25rem',
          lg1: '1.5rem',
          lg2: '1.625rem',
          xl: '2rem',
        },
        h3: {
          sm1: '1rem',
          sm2: '1rem',
          md: '1.125rem',
          lg1: '1.25rem',
          lg2: '1.5rem',
          xl: '1.625rem',
        },
        h4: {
          sm1: '1rem',
          sm2: '1.125rem',
          md: '1.125rem',
          lg1: '1.25rem',
          lg2: '1.375rem',
          xl: '1.375rem',
        },
        h5: {
          sm1: '1rem',
          sm2: '1.125rem',
          md: '1.125rem',
          lg1: '1.25rem',
          lg2: '1.25rem',
          xl: '1.25rem',
        },
        h6: {
          sm1: '1rem',
          sm2: '1rem',
          md: '1rem',
          lg1: '1.25rem',
          lg2: '1.25rem',
          xl: '1.125rem',
        },
        subtitle: {
          sm1: '0.625rem',
          sm2: '0.75rem',
          md: '0.875rem',
          lg1: '0.875rem',
          lg2: '1rem',
          xl: '1rem',
        },
        text: {
          sm1: '0.875rem',
          sm2: '1rem',
          md: '1.125rem',
          lg1: '1.25rem',
          lg2: '1.375rem',
          xl: '1.625rem',
        },
      },
    },
    lineHeight: {
      desktop: {
        h1: {
          sm1: '1.563rem',
          sm2: '1.75rem',
          md: '2.188rem',
          lg1: '2.5rem',
          lg2: '3.563rem',
          xl: '4.5rem',
        },
        h2: {
          sm1: '1.125rem',
          sm2: '1.25rem',
          md: '2rem',
          lg1: '2.5rem',
          lg2: '2.813rem',
          xl: '3.563rem',
        },
        h3: {
          sm1: '1.125rem',
          sm2: '1.25rem',
          md: '1.438rem',
          lg1: '1.875rem',
          lg2: '2.5rem',
          xl: '3.125rem',
        },
        h4: {
          sm1: '1.125rem',
          sm2: '1.25rem',
          md: '1.438rem',
          lg1: '1.75rem',
          lg2: '2.188rem',
          xl: '2.625rem',
        },
        h5: {
          sm1: '1.125rem',
          sm2: '1.25rem',
          md: '1.438rem',
          lg1: '1.563rem',
          lg2: '1.875rem',
          xl: '2.188rem',
        },
        h6: {
          sm1: '1.125rem',
          sm2: '1.125rem',
          md: '1.125rem',
          lg1: '1.125rem',
          lg2: '1.25rem',
          xl: '1.375rem',
        },
        subtitle: {
          sm1: '0.813rem',
          sm2: '0.938rem',
          md: '1.125rem',
          lg1: '1.25rem',
          lg2: '1.25rem',
          xl: '1.25rem',
        },
        text: {
          sm1: '0.938rem',
          sm2: '1.063rem',
          md: '1.375rem',
          lg1: '1.438rem',
          lg2: '1.563rem',
          xl: '2.5rem',
        },
      },
      mobile: {
        h1: {
          sm1: '1.25rem',
          sm2: '1.438rem',
          md: '1.438rem',
          lg1: '1.75rem',
          lg2: '2.188rem',
          xl: '2.188rem',
        },
        h2: {
          sm1: '1.25rem',
          sm2: '1.438rem',
          md: '1.438rem',
          lg1: '1.563rem',
          lg2: '1.875rem',
          xl: '2rem',
        },
        h3: {
          sm1: '1.25rem',
          sm2: '1.438rem',
          md: '1.438rem',
          lg1: '1.563rem',
          lg2: '1.875rem',
          xl: '1.875rem',
        },
        h4: {
          sm1: '1.25rem',
          sm2: '1.438rem',
          md: '1.438rem',
          lg1: '1.563rem',
          lg2: '1.688rem',
          xl: '1.688rem',
        },
        h5: {
          sm1: '1.25rem',
          sm2: '1.438rem',
          md: '1.438rem',
          lg1: '1.563rem',
          lg2: '1.563rem',
          xl: '1.563rem',
        },
        h6: {
          sm1: '1.25rem',
          sm2: '1.438rem',
          md: '1.438rem',
          lg1: '1.438rem',
          lg2: '1.438rem',
          xl: '1.375rem',
        },
        subtitle: {
          sm1: '0.813rem',
          sm2: '0.938rem',
          md: '1.125rem',
          lg1: '1.125rem',
          lg2: '1.25rem',
          xl: '1.25rem',
        },
        text: {
          sm1: '0.938rem',
          sm2: '1.125rem',
          md: '1.25rem',
          lg1: '1.375rem',
          lg2: '1.563rem',
          xl: '1.563rem',
        },
      },
      tablet: {
        h1: {
          sm1: '1.25rem',
          sm2: '1.438rem',
          md: '1.75rem',
          lg1: '2rem',
          lg2: '2.5rem',
          xl: '3rem',
        },
        h2: {
          sm1: '1.25rem',
          sm2: '1.438rem',
          md: '1.625rem',
          lg1: '2rem',
          lg2: '2rem',
          xl: '2.5rem',
        },
        h3: {
          sm1: '1.25rem',
          sm2: '1.438rem',
          md: '1.438rem',
          lg1: '1.438rem',
          lg2: '1.875rem',
          xl: '2rem',
        },
        h4: {
          sm1: '1.25rem',
          sm2: '1.438rem',
          md: '1.438rem',
          lg1: '1.563rem',
          lg2: '1.688rem',
          xl: '1.688rem',
        },
        h5: {
          sm1: '1.25rem',
          sm2: '1.438rem',
          md: '1.438rem',
          lg1: '1.563rem',
          lg2: '1.563rem',
          xl: '1.563rem',
        },
        h6: {
          sm1: '1.25rem',
          sm2: '1.25rem',
          md: '1.25rem',
          lg1: '1.563rem',
          lg2: '1.563em',
          xl: '1.375rem',
        },
        subtitle: {
          sm1: '0.813rem',
          sm2: '0.938rem',
          md: '1.125rem',
          lg1: '1.125rem',
          lg2: '1.25rem',
          xl: '1.25rem',
        },
        text: {
          sm1: '1.125rem',
          sm2: '1.375rem',
          md: '1.438rem',
          lg1: '1.563rem',
          lg2: '1.875rem',
          xl: '2rem',
        },
      },
    },
    fontWeight: {
      light: '300',
      regular: '400',
      medium: '500',
      semiBold: '600',
      bold: '700',
      extraBold: '800',
      black: '900',
    },
  },
  breakpoints: {
    xs: '0px',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    xxl: '1400px',
  },
  containerMaxWidth: {
    sm: '540px',
    md: '720px',
    lg: '960px',
    xl: '1140px',
    xxl: '1320px',
  },
  spacings: {
    none: '0',
    xs: '0.5rem',
    sm: '1rem',
    md: '2rem',
    lg: '3rem',
    xl: '4rem',
  },
  border: {
    radius: {
      none: '0',
      sm: '0.25rem',
      md: '0.5rem',
      lg: '1rem',
      rounded: '50%',
    },
  },
  sidebar: {
    width: '16.5rem',
    collapsedWidth: '4.5rem',
  },
};

export default defaultTheme;
