import React from 'react';

// Enumerators
// //////////////////////////

export interface AuthContext {
  route:
    | 'idle'
    | 'signIn'
    // 'confirmSignIn' | // For MFA confirmation
    | 'forceNewPassword'
    | 'resetPassword'
    | 'confirmResetPassword'
    | 'signOut'
    | 'authenticated';
  inProgress: boolean;
  error: string | null;

  user: Record<string, unknown>;

  signIn: (username: string, password: string) => Promise<void>;
  signOut: () => Promise<void>;
  resetPassword: (username: string) => Promise<void>;
  confirmResetPassword: (
    username: string,
    code: string,
    password: string,
  ) => Promise<void>;
  completeNewPassword: (password: string) => Promise<void>;
  setRoute: React.Dispatch<React.SetStateAction<AuthContext['route']>>;
}

// Context
// //////////////////////////

// The initial authorization context
const authContext = React.createContext<AuthContext>({
  route: 'idle',
  inProgress: false,
  error: null,
  user: {},

  signIn: async () => {},
  signOut: async () => {},
  resetPassword: async () => {},
  confirmResetPassword: async () => {},
  completeNewPassword: async () => {},

  setRoute: () => null,
});

export default authContext;
