import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useInterval } from '@quality24/react-hooks';

import { runtimeConfig } from '../../config';
import { getRedirectUri } from '../../utils/url';

const UnauthorizedPage: React.FunctionComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();

  /**
   * Redirects user after 5 seconds
   */
  useInterval(() => {
    const uri = getRedirectUri(location.search);
    if (!process.env.REACT_APP_PREVENT_REDIRECT) {
      if (uri) {
        navigate(uri);
      } else {
        window.location.replace(
          runtimeConfig.REDIRECT_URI ||
            (process.env.REACT_APP_REDIRECT_URI as string),
        );
      }
    }
  }, 5000);

  return (
    <div>
      Ocorreu um erro na sua requisição. Você será redirecionado em 5 segundos.
    </div>
  );
};

export default UnauthorizedPage;
