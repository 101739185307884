import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Button, InputWithIcon, PasswordInput } from '@quality24/design-system';
import { faEnvelope, faLock } from '@fortawesome/pro-regular-svg-icons';
import classNames from 'classnames';

export interface Props {
  className?: string;
  initialValues?: FormValues;
  loading: boolean;
  onSubmit: (values: FormValues) => Promise<void>;
  onChangePassword: (username?: string) => void;
}

export interface FormValues {
  email: string;
  password: string;
}

/**
 * Login form default values
 */
const defaultValues: FormValues = {
  email: '',
  password: '',
};

// The form validation schema
const validationSchema = yup.object({
  email: yup.string().required('Campo obrigatório'),
  password: yup.string().required('Campo obrigatório'),
});

/**
 * <LoginForm> component
 */
const LoginForm: React.FunctionComponent<Props> = ({
  className,
  initialValues = {},
  loading,
  onSubmit,
  onChangePassword,
}) => {
  const { handleSubmit, values, errors, setFieldValue } = useFormik({
    initialValues: { ...defaultValues, ...initialValues },
    validationSchema,
    onSubmit,
  });

  return (
    <form
      className={classNames(
        'd-flex flex-column flex-justify-between h-100',
        className,
      )}
      onSubmit={handleSubmit}
    >
      <div>
        <InputWithIcon
          id="email-input"
          className="mb-4"
          placeholder="Informe seu e-mail"
          label="E-mail"
          name="email"
          type="email"
          prependIcon={faEnvelope}
          value={values?.email}
          errorText={errors?.email}
          onChange={({ target }) => {
            setFieldValue(target.name, target.value);
          }}
        />

        <PasswordInput
          id="password-input"
          className="mb-2"
          placeholder="Digite sua senha"
          label="Senha"
          name="password"
          prependIcon={faLock}
          value={values?.password}
          errorText={errors?.password}
          onChange={({ target }) => {
            setFieldValue(target.name, target.value);
          }}
        />

        <div className="d-flex justify-content-end mb-4">
          <Button
            variant="link"
            size="sm"
            onClick={() => onChangePassword(values.email)}
          >
            Esqueci a senha
          </Button>
        </div>
      </div>

      <div className="d-flex flex-items-center flex-justify-between mt-3">
        <Button size="lg" type="submit" disabled={loading}>
          Entrar
        </Button>
      </div>
    </form>
  );
};

export default LoginForm;
