import * as React from 'react';
import { Text } from '@quality24/design-system';

import LoginForm, {
  LoginFormValues,
} from '../../components/organisms/LoginForm';

export interface Props {
  isFirstAccess: boolean;
  inProgress: boolean;
  initialValues: LoginFormValues;
  onLogin: (values: LoginFormValues) => Promise<void>;
  onChangePassword: (username: string | undefined) => void;
}

const SignInSection: React.FunctionComponent<Props> = ({
  isFirstAccess,
  initialValues,
  inProgress,
  onLogin,
  onChangePassword,
}) => (
  <>
    <Text weight="light">
      Seja <strong>bem-vindo</strong>, faça o login para acessar sua conta
    </Text>

    {isFirstAccess && (
      <Text weight="light" className="mt-2">
        Se este é seu <strong>primeiro acesso</strong>, coloque a senha
        temporária que você recebeu em seu email
      </Text>
    )}

    <LoginForm
      className="mt-3"
      initialValues={initialValues}
      loading={inProgress}
      onSubmit={onLogin}
      onChangePassword={onChangePassword}
    />
  </>
);

export default SignInSection;
