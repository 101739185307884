import React from 'react';
import { useFormik } from 'formik';
import { Button, PasswordInput } from '@quality24/design-system';
import { faLock } from '@fortawesome/pro-regular-svg-icons';
import * as yup from 'yup';

import PasswordChecker from '../../molecules/PasswordChecker';

export interface Props {
  initialValues?: FormValues;
  loading: boolean;
  onSubmit: (values: FormValues) => Promise<void>;
}

export interface FormValues {
  newPassword: string;
  confirmPassword: string;
}

/**
 * New password form default values
 */
const defaultValues = {
  newPassword: '',
  confirmPassword: '',
};

const isArrayNotEmpty = (arr: RegExpMatchArray | null) =>
  Boolean(Array.isArray(arr) && arr.length);

// The form validation schema
const validationSchema = yup.object({
  newPassword: yup
    .string()
    .min(8, 'A senha precisa ter ao menos 8 caracteres')
    .test({
      name: 'passwordValidation',
      message:
        'A senha precisa ter ao menos uma letra maiúscula, uma letra minúscula, um dígito e um caractere especial',
      test: (value) => {
        if (!value) return true;
        const hasLower = value.match(/[a-z]/g);
        const hasUpper = value.match(/[A-Z]/g);
        const hasDigit = value.match(/[0-9]/g);
        // https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-policies.html
        const hasSpecialCharacter = value.match(
          /[\^$*.[\]{}()?\-“!@#%&/,><’:;|_~`"'\\]/g,
        );
        return (
          isArrayNotEmpty(hasLower) &&
          isArrayNotEmpty(hasUpper) &&
          isArrayNotEmpty(hasDigit) &&
          isArrayNotEmpty(hasSpecialCharacter)
        );
      },
    })
    .required('Campo Obrigatório'),
  confirmPassword: yup
    .string()
    .oneOf(
      [yup.ref('newPassword'), null],
      'A confirmação precisa ser igual a senha',
    )
    .required('Campo Obrigatório'),
});

/**
 * <NewPasswordForm> component
 */
const NewPasswordForm: React.FunctionComponent<Props> = ({
  initialValues,
  loading,
  onSubmit,
}) => {
  const { handleSubmit, values, isValid, setFieldValue, isSubmitting } =
    useFormik({
      initialValues: { ...defaultValues, ...initialValues },
      validationSchema,
      isInitialValid: false,
      onSubmit,
    });

  const hasProperLength = values.newPassword?.length >= 8;
  const hasLowerCase = isArrayNotEmpty(values.newPassword.match(/[a-z]/g));
  const hasUpperCase = isArrayNotEmpty(values.newPassword.match(/[A-Z]/g));
  const hasDigit = isArrayNotEmpty(values.newPassword.match(/[0-9]/g));
  const hasSpecialCharacter = isArrayNotEmpty(
    values.newPassword.match(/[\^$*.[\]{}()?\-“!@#%&/,><’:;|_~`"'\\]/g),
  );
  const passwordsMatch =
    values.newPassword?.length > 0 &&
    values.newPassword === values.confirmPassword;

  return (
    <form
      className="d-flex flex-column flex-justify-between h-100"
      onSubmit={handleSubmit}
    >
      <div>
        <PasswordInput
          id="newPassword-input"
          className="mb-4"
          label="Nova senha"
          placeholder="Digite sua senha"
          name="newPassword"
          prependIcon={faLock}
          value={values?.newPassword}
          onChange={({ target }) => {
            setFieldValue(target.name, target.value);
          }}
        />

        <PasswordInput
          id="confirmPassword-input"
          className="mb-4"
          label="Confirme a senha"
          placeholder="Digite novamente sua senha"
          name="confirmPassword"
          prependIcon={faLock}
          value={values?.confirmPassword}
          onChange={({ target }) => {
            setFieldValue(target.name, target.value);
          }}
        />

        <div className="mb-4">
          <PasswordChecker
            description="8 ou mais caracteres"
            valid={hasProperLength}
            isSubmitting={isSubmitting}
          />

          <PasswordChecker
            description="Letras maiúsculas e minúsculas"
            valid={hasLowerCase && hasUpperCase}
            isSubmitting={isSubmitting}
          />

          <PasswordChecker
            description="Pelo menos 1 número"
            valid={hasDigit}
            isSubmitting={isSubmitting}
          />

          <PasswordChecker
            description="Pelo menos 1 caractere especial"
            // Commenting the examples because it breaks the ForgotPasswordPage
            // examples="(~!@#$%^&*_-+='|\(){}[],.? /)"
            valid={hasSpecialCharacter}
            isSubmitting={isSubmitting}
          />

          <PasswordChecker
            description="As senha devem coincidir"
            valid={passwordsMatch}
            isSubmitting={isSubmitting}
          />
        </div>
      </div>

      <div className="d-flex flex-items-center flex-justify-end">
        <Button type="submit" size="lg" disabled={loading || !isValid}>
          Alterar senha
        </Button>
      </div>
    </form>
  );
};

export default NewPasswordForm;
