import React from 'react';
import { useFormik } from 'formik';
import { Button, InputWithIcon } from '@quality24/design-system';
import { faArrowLeft, faEnvelope } from '@fortawesome/pro-regular-svg-icons';
import * as yup from 'yup';

export interface Props {
  initialValues?: FormValues;
  loading: boolean;
  onSubmit: (values: FormValues) => Promise<void>;
  onGoBack?: () => void;
}

export interface FormValues {
  email: string;
}

/**
 * Forgot password form default values
 */
const defaultValues: FormValues = {
  email: '',
};

// The form validation schema
const validationSchema = yup.object({
  email: yup.string().required('Campo obrigatório'),
});

/**
 * <ForgotPasswordForm> component
 */
const ForgotPasswordForm: React.FunctionComponent<Props> = ({
  initialValues = {},
  loading,
  onSubmit,
  onGoBack,
}) => {
  const { values, errors, setFieldValue, handleSubmit } = useFormik({
    initialValues: { ...defaultValues, ...initialValues },
    validationSchema,
    onSubmit,
  });

  return (
    <form
      className="d-flex flex-column flex-justify-between h-100"
      onSubmit={handleSubmit}
    >
      <InputWithIcon
        id="email-input"
        placeholder="Informe seu e-mail"
        label="E-mail"
        name="email"
        type="email"
        prependIcon={faEnvelope}
        value={values?.email}
        errorText={errors?.email}
        onChange={({ target }) => {
          setFieldValue(target.name, target.value);
        }}
      />

      <div className="d-flex flex-items-center flex-justify-between mt-3">
        {!!onGoBack && (
          <Button variant="link" icon={faArrowLeft} onClick={onGoBack}>
            Voltar
          </Button>
        )}

        <Button type="submit" size="lg" disabled={loading}>
          Enviar confirmação
        </Button>
      </div>
    </form>
  );
};

export default ForgotPasswordForm;
