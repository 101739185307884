import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Alert, SpinnerOverlay } from '@quality24/design-system';

import { parseQuery, stringifyQuery } from '../../utils/url';
import { useAuth } from '../../contexts/auth/useAuth';
import { LoginFormValues } from '../../components/organisms/LoginForm';
import { NewPasswordFormValues } from '../../components/organisms/NewPasswordForm';
import SignInSection from './SignInSection';
import NewPasswordSection from './NewPasswordSection';

const LoginPage: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { route, inProgress, error, signIn, completeNewPassword, setRoute } =
    useAuth();

  // Get the query string object
  const query = parseQuery(location.search);
  const isFirstAccess =
    query?.firstAccess !== null && query?.firstAccess !== undefined;

  // Set initial page route
  React.useEffect(() => {
    if (!route) {
      setRoute('signIn');
    }
  }, [setRoute, route]);

  /**
   * Signs in the user
   */
  const handleLogin = React.useCallback(
    async ({ email, password }: LoginFormValues) => {
      try {
        await signIn(email, password);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Failed to login: ', err);
      }
    },
    [signIn],
  );

  /**
   * Changes user password
   */
  const handleChangePassword = React.useCallback(
    async ({ newPassword }: NewPasswordFormValues) => {
      try {
        await completeNewPassword(newPassword);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Failed to set new password: ', err);
      }
    },
    [completeNewPassword],
  );

  const initialValues: LoginFormValues = {
    email: (query.username as string) || '',
    password: '',
  };

  return (
    <>
      {error && (
        <Alert className="mb-3" variant="danger">
          {error}
        </Alert>
      )}
      {inProgress && <SpinnerOverlay />}

      {route === 'signIn' && (
        <SignInSection
          isFirstAccess={isFirstAccess}
          initialValues={initialValues}
          inProgress={inProgress}
          onLogin={handleLogin}
          onChangePassword={(username) => {
            const search = username ? { username, ...query } : query;
            setRoute('resetPassword');
            navigate(`/forgot-password?${stringifyQuery(search)}`);
          }}
        />
      )}

      {route === 'forceNewPassword' && (
        <NewPasswordSection
          inProgress={inProgress}
          onPasswordChange={handleChangePassword}
        />
      )}
    </>
  );
};

export default LoginPage;
