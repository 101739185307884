import Cookies from 'js-cookie';
import { runtimeConfig } from '../config';

class CookieStorage {
  // set item with the key
  static setItem(key: string, value: string): string {
    Cookies.set(key, value, {
      domain:
        process.env.NODE_ENV === 'production' ? 'quality24.com.br' : undefined,
      sameSite: 'strict',
      secure: true,
    });
    return value;
  }

  // get item with the key
  static getItem(key: string): string {
    return Cookies.get(key) || '';
  }

  // remove item with the key
  static removeItem(key: string): void {
    Cookies.remove(key, {
      domain:
        process.env.NODE_ENV === 'production' ? 'quality24.com.br' : undefined,
    });
  }

  // clear out the storage
  static clear(): void {
    const cookies = Cookies.get();
    Object.keys(cookies).forEach((k) => CookieStorage.removeItem(k));
  }

  // If the storage operations are async(i.e AsyncStorage)
  // Then you need to sync those items into the memory in this method
  // static sync(): Promise<void> {
  //   if (!CookieStorage.syncPromise) {
  //     CookieStorage.syncPromise = new Promise((_res, _rej) => {});
  //   }
  //   return CookieStorage.syncPromise;
  // }
}

export default {
  Auth: {
    userPoolId:
      runtimeConfig.COGNITO_USER_POOL_ID ||
      process.env.REACT_APP_COGNITO_USER_POOL_ID ||
      '',
    userPoolWebClientId:
      runtimeConfig.COGNITO_CLIENT_ID ||
      process.env.REACT_APP_COGNITO_CLIENT_ID ||
      '',
    region:
      runtimeConfig.COGNITO_REGION ||
      process.env.REACT_APP_COGNITO_REGION ||
      'us-east-1',

    storage: CookieStorage,
  },
};
