import React from 'react';
import { useFormik } from 'formik';
import { Button, InputWithIcon } from '@quality24/design-system';
import { faArrowLeft, faKey } from '@fortawesome/pro-regular-svg-icons';
import * as yup from 'yup';

export interface Props {
  initialValues?: FormValues;
  loading: boolean;
  onSubmit: (values: FormValues) => Promise<void>;
  onResend: (values: { email: string }) => Promise<void>;
  onGoBack: () => void;
}

export interface FormValues {
  email: string;
  confirmationCode: string;
}

/**
 * Login form default values
 */
const defaultValues = {
  email: '',
  confirmationCode: '',
};

// The form validation schema
const validationSchema = yup.object({
  email: yup.string().required('Campo obrigatório'),
  confirmationCode: yup.string().required('Campo obrigatório'),
});

/**
 * <ConfirmForgotPasswordForm> component
 */
const ConfirmForgotPasswordForm: React.FunctionComponent<Props> = ({
  initialValues,
  loading,
  onSubmit,
  onResend,
  onGoBack,
}) => {
  const { values, errors, setFieldValue, handleSubmit } = useFormik({
    initialValues: { ...defaultValues, ...initialValues },
    validationSchema,
    onSubmit,
  });

  return (
    <form
      className="d-flex flex-column flex-justify-between h-100"
      onSubmit={handleSubmit}
    >
      <div>
        <InputWithIcon
          id="confirmationCode-input"
          className="mb-3"
          placeholder="Insira aqui o código de confirmação"
          label="Código de Confirmação"
          name="confirmationCode"
          prependIcon={faKey}
          value={values?.confirmationCode}
          errorText={errors.confirmationCode}
          onChange={({ target }) => {
            setFieldValue(target.name, target.value);
          }}
        />

        <div className="d-flex justify-content-end mb-4">
          <Button
            variant="link"
            size="sm"
            disabled={loading}
            onClick={() => onResend({ email: values.email })}
          >
            Re-enviar código de confirmação
          </Button>
        </div>
      </div>

      <div className="d-flex flex-items-center flex-justify-between">
        {!!onGoBack && (
          <Button
            variant="link"
            size="lg"
            icon={faArrowLeft}
            onClick={onGoBack}
          >
            Voltar
          </Button>
        )}

        <Button type="submit" size="lg" disabled={loading}>
          Verificar
        </Button>
      </div>
    </form>
  );
};

export default ConfirmForgotPasswordForm;
