import * as React from 'react';
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons';

import { InputProps } from '../../atoms';
import InputWithIcon from '../InputWithIcon';

export interface Props extends InputProps {
  prependIcon?: FontAwesomeIconProps['icon'];
}

const PasswordInput = React.forwardRef<HTMLInputElement, Props>(
  ({ prependIcon, ...inputProps }, ref) => {
    const [show, setShow] = React.useState(false);

    return (
      <InputWithIcon
        ref={ref}
        type={show ? 'text' : 'password'}
        prependIcon={prependIcon}
        appendIcon={show ? faEyeSlash : faEye}
        onAppendClick={() => setShow(!show)}
        {...inputProps}
      />
    );
  },
);

export default PasswordInput;
