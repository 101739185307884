import * as React from 'react';
import { Button, Text } from '@quality24/design-system';
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons';

import NewPasswordForm, {
  NewPasswordFormValues,
} from '../../components/organisms/NewPasswordForm';

export interface Props {
  username: string;
  inProgress: boolean;
  onSubmit: (values: NewPasswordFormValues) => Promise<void>;
}

const NewPasswordSection: React.FunctionComponent<Props> = ({
  username,
  inProgress,
  onSubmit,
}) => (
  <>
    <Text className="mb-3" weight="light">
      Preencha os campos abaixo para redefinir sua senha.
    </Text>

    <Button
      variant="outline"
      className="mb-4"
      icon={faEnvelope}
      ellipsize
      disabled
    >
      {username}
    </Button>

    <NewPasswordForm loading={inProgress} onSubmit={onSubmit} />
  </>
);

export default NewPasswordSection;
