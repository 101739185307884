export enum FrontEndError {
  'Q1001' = 1001,
  'Q1002' = 1002,
  'Q1011' = 1011,
}

const FRONTEND_ERROR_CODE: Record<FrontEndError, string> = {
  1001: 'Parametros inválidos na URL. cod: Q1001',
  1002: 'Não foi possivel identificar o local de abertura. cod: Q1002',
  1011: 'Usuário não tem permissão para acessar este app. cod: Q1011',
};

export default FRONTEND_ERROR_CODE;
