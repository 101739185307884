import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Alert, SpinnerOverlay } from '@quality24/design-system';

import {
  FRONTEND_ERROR_CODE as ERROR_CODE,
  FrontEndError,
} from '@quality24/error-codes';
import { getRedirectUri } from '../../utils/url';
import { useAuth } from '../../contexts/auth/useAuth';

const LogoutPage: React.FunctionComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { inProgress, error, signOut } = useAuth();

  React.useEffect(() => {
    signOut().then(() => {
      const uri = getRedirectUri(location.search);
      if (!process.env.REACT_APP_PREVENT_REDIRECT) {
        if (uri) {
          window.location.replace(uri);
        } else {
          navigate('/login');
        }
      }
    });
  }, [signOut, location, navigate]);

  const message = React.useMemo(() => {
    const query = new URLSearchParams(location.search);
    const code = query.get('code');
    const parsedMessage = ERROR_CODE[code as unknown as FrontEndError];
    return parsedMessage;
  }, [location.search]);

  return (
    <>
      {error && (
        <Alert className="mb-3" variant="danger">
          {error}
        </Alert>
      )}
      {inProgress && <SpinnerOverlay />}

      {message && (
        <div className="mb-2" data-testid="message">
          {message}
        </div>
      )}
      <div>Aguarde nesta tela enquanto te deslogamos.</div>
    </>
  );
};

export default LogoutPage;
