import React from 'react';
import { Text, Image, Surface } from '@quality24/design-system';

import styles from './LoginTemplate.module.scss';

export interface Props {
  copyright: string;
  logo: string;
  children: React.ReactNode;
}

const MobileTemplate: React.FunctionComponent<Props> = ({
  copyright,
  logo,
  children,
}) => (
  <div className={styles['mobile-wrapper']}>
    <Image src={logo} alt="Quality24 - Logo" className="mb-4" width={179} />

    <main className="mb-4">
      <Surface className={styles.surface}>{children}</Surface>
    </main>

    <Text size="sm2" weight="light" color="grayLight1">
      {copyright}
    </Text>
  </div>
);

export default MobileTemplate;
