import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmarkLarge } from '@fortawesome/pro-regular-svg-icons';
import classNames from 'classnames';

import ModalFooter, { Props as ModalFooterProps } from './ModalFooter';
import Portal from '../../atoms/Portal';
import Heading from '../../atoms/Heading';
import Button from '../../atoms/Button';
import Text from '../../atoms/Text';

import styles from './Modal.module.scss';
import { ColorStyleProps } from '../../../@types/theme';

export interface Props extends React.HTMLAttributes<HTMLElement> {
  id: string;
  show: boolean;
  portalId?: string;
  onHide?: () => void;
  title?: string;
  footer?: React.ReactNode;
  closeButton?: boolean;
  backdrop?: boolean;
  children?: React.ReactNode;
  isScrollable?: boolean;
  backgroundColor?: ColorStyleProps;
}

interface ModalSubComponents {
  Footer: React.FunctionComponent<ModalFooterProps>;
}

export const Modal: React.FunctionComponent<Props> & ModalSubComponents = ({
  id,
  className,
  style,
  show = false,
  portalId = 'default-modal-portal',
  onHide = () => null,
  title = '',
  backdrop = true,
  closeButton = true,
  footer,
  children,
  isScrollable = false,
  backgroundColor = 'grayLight3',
  ...htmlElementProps
}: Props) => {
  const modalRef = React.useRef<HTMLDivElement | null>(null);

  const closeModal: React.MouseEventHandler<HTMLDivElement> = (e) => {
    if (backdrop && modalRef.current === e.target) {
      onHide();
    }
  };

  // Close modal with escape key
  React.useEffect(() => {
    const closeOnEscapeKey = (e: KeyboardEvent) =>
      e.key === 'Escape' ? onHide() : null;
    document.body.addEventListener('keydown', closeOnEscapeKey);
    return () => {
      document.body.removeEventListener('keydown', closeOnEscapeKey);
    };
  }, [onHide]);

  /**
   * Hide body scroll if modal is open
   * This is NOT useful for nested modals as body's overflow will become auto again when the nested modal is closed
   * If you have nested modals, prefer useModal hook
   */
  React.useEffect(() => {
    document.body.style.overflowY = show ? 'hidden' : 'auto';
    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, [show]);

  if (!show) return null;
  return (
    <Portal wrapperId={portalId}>
      <div
        id={id}
        className={classNames(styles.background, {
          scrollable: isScrollable,
        })}
        onClick={closeModal}
        role="presentation"
        ref={modalRef}
      >
        <div
          className={classNames(styles.wrapper, className, {
            scrollable: isScrollable,
            [`bg-${backgroundColor}`]: backgroundColor,
          })}
          style={style}
          role="dialog"
          aria-modal
          {...htmlElementProps}
        >
          <div className={classNames(styles.header, { 'mb-0': !title })}>
            <Heading
              as="h1"
              className="flex-grow-1"
              size="sm2"
              weight="semiBold"
            >
              {title}
            </Heading>

            {closeButton && (
              <Button
                className={styles['close-button']}
                variant="link"
                onClick={() => onHide()}
                aria-label="close button"
              >
                <FontAwesomeIcon icon={faXmarkLarge} />
              </Button>
            )}
          </div>

          <div className={styles.content}>
            <Text as="span" size="sm2" weight="light">
              {children}
            </Text>
          </div>

          {footer && <ModalFooter>{footer}</ModalFooter>}
        </div>
      </div>
    </Portal>
  );
};

Modal.Footer = ModalFooter;

export default Modal;
