export interface RuntimeConfig {
  REDIRECT_URI?: string;
  COGNITO_USER_POOL_ID?: string;
  COGNITO_CLIENT_ID?: string;
  COGNITO_REGION?: string;
}

export const runtimeConfig =
  (window as unknown as Record<'runtimeConfig', RuntimeConfig>).runtimeConfig ||
  {};

export default runtimeConfig;
