import * as React from 'react';
import classNames from 'classnames';
import { faBars, faRightFromBracket } from '@fortawesome/pro-regular-svg-icons';

import {
  SidebarCollapseButton,
  Props as CollapseButtonProps,
} from './SidebarCollapseButton';
import {
  SidebarContextProvider,
  Props as ProviderProps,
} from './utils/context';

import { useSidebar } from './utils/useSidebar';
import Text from '../../atoms/Text';
import Image from '../../atoms/Image';

import styles from './Sidebar.module.scss';
import Icon, { IconProps } from '../../atoms/Icon';

interface SidebarSubComponents {
  CollapseButton: React.FunctionComponent<CollapseButtonProps>;
  ContextProvider: React.FunctionComponent<ProviderProps>;
}

export interface MenuGroup {
  id: string;
  label: string;
  items: Array<MenuItem>;
}

export interface MenuItem {
  id: string;
  className?: string;
  children: React.ReactNode;
  to: string;
  icon?: IconProps['icon'];
  end?: boolean;
}

export interface Props extends React.HTMLAttributes<HTMLElement> {
  menu: Array<MenuGroup>;
  onLogout?: () => void;
  // Here we need it to be any so it is generic
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  menuItemAs?: React.ElementType<any>;
}

/**
 * <Sidebar> component
 */
export const Sidebar: React.FunctionComponent<Props> & SidebarSubComponents = ({
  className,
  style,
  menu,
  menuItemAs = 'button',
  onLogout,
  ...htmlElementProps
}: Props) => {
  const [mobileShow, setMobileShow] = React.useState(false);
  const [element, setElement] = React.useState<HTMLElement | null>(null);

  // Get collapse state from context
  const { collapsed } = useSidebar();

  // Menu item component
  const MenuItem = menuItemAs;

  /**
   * Hide overlay if user clicks outside the overlay
   */
  React.useEffect(() => {
    const handler = (event: MouseEvent) => {
      if (
        mobileShow &&
        event.target !== element &&
        !element?.contains(event.target as Node)
      ) {
        setMobileShow(false);
      }
    };
    document.addEventListener('mousedown', handler);

    return () => document.removeEventListener('mousedown', handler);
  });

  return (
    <nav
      className={classNames(styles.sidebar, className, {
        collapsed,
        show: mobileShow,
      })}
      {...htmlElementProps}
    >
      <section className={styles['mobile-wrapper']}>
        {/* Mobile hamburger button */}
        <button
          type="button"
          className={classNames(styles.button, 'expand')}
          aria-label="Abrir menu"
          onClick={() => setMobileShow((prev) => !prev)}
        >
          <Icon icon={faBars} size="sm" />
        </button>

        {/* Logo heading */}
        <div className={styles['logo-wrapper']}>
          <Image
            src="https://d9d5m694col3p.cloudfront.net/images/branding/logo_quality24_light_color_256px.png"
            alt="Logo Quality 24"
            width={125}
            style={{ display: collapsed ? 'none' : 'inherit' }}
          />
          <Image
            src="https://d9d5m694col3p.cloudfront.net/images/branding/logo_quality24_short_light_color_64px.png"
            alt="Logo Quality 24 Short"
            width={58}
            style={{ display: collapsed ? 'inherit' : 'none' }}
          />
        </div>
      </section>

      {/* Menu */}
      <div ref={setElement} className={styles['expandable-wrapper']}>
        <div className={styles.menu}>
          {menu.map((group) => (
            <div key={group.id}>
              <Text
                className={styles['group-label']}
                size="sm1"
                weight="bold"
                isUpperCase
              >
                {group.label}
              </Text>

              <div className={styles.menu}>
                {group.items.map(
                  ({ icon, children, className: itemClassName, ...item }) => (
                    <MenuItem
                      key={item.id}
                      type="button"
                      className={classNames(
                        styles.button,
                        'item',
                        itemClassName,
                      )}
                      {...item}
                    >
                      {icon && <Icon icon={icon} size="sm" />}
                      <span className={styles['item-label']}>{children}</span>
                    </MenuItem>
                  ),
                )}
              </div>
            </div>
          ))}

          {/* Logout button */}
          <button
            type="button"
            aria-label="Sair"
            className={classNames(styles.button, 'item')}
            onClick={onLogout}
          >
            <Icon icon={faRightFromBracket} size="sm" />
            <span className={styles['item-label']}>Sair</span>
          </button>
        </div>

        {/* App version */}
        <span className={styles['version-label']}>
          {`v${process.env.REACT_APP_VERSION}`}
        </span>
      </div>
    </nav>
  );
};

Sidebar.CollapseButton = SidebarCollapseButton;
Sidebar.ContextProvider = SidebarContextProvider;

export default Sidebar;
