import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Auth, CognitoUser } from '@aws-amplify/auth';

import { parseQuery } from '../../utils/url';
import authContext, { AuthContext } from './authContext';

export interface Props {
  children: React.ReactNode;
}

/**
 * Map of cognito errors
 */
const CognitoErrors: Record<string, string> = {
  NotAuthorizedException: 'Usuário ou senha inválidos',
  CodeMismatchException: 'Código de validação inválido',
  ExpiredCodeException: 'Seu código de validação expirou',
};

/**
 * Parses error messages for the user.
 * @param err
 * @returns
 */
const parseError = (err: Error) => {
  const errMessage = CognitoErrors[err.name];
  return errMessage || 'Um erro inesperado aconteceu. Tente novamente.';
};

/**
 * <AuthenticationProvider> component
 */
const AuthenticationProvider: React.FunctionComponent<Props> = ({
  children,
}) => {
  const [route, setRoute] = React.useState<AuthContext['route']>('idle');
  const [user, setUser] = React.useState<CognitoUser | null>(null);
  const [inProgress, setInProgress] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string | null>(null);

  // Read clientId from URL
  const location = useLocation();
  const navigate = useNavigate();
  const query = parseQuery(location.search);

  /**
   * Wrappers the function's in progress status
   */
  const progressWrapper = React.useCallback(
    async <T,>(fn: () => Promise<T>) => {
      try {
        setInProgress(true);
        setError(null);
        const result = await fn();
        return result;
      } catch (err) {
        setError(parseError(err as Error));
        throw err;
      } finally {
        setInProgress(false);
      }
    },
    [setError, setInProgress],
  );

  /**
   * Sign in process
   */
  const signIn = React.useCallback(
    async (username: string, password: string) => {
      const res = await progressWrapper(() => Auth.signIn(username, password));
      if (res.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setUser(res);
        setRoute('forceNewPassword');
        return res;
      }

      setRoute('authenticated');
      return res;
    },
    [progressWrapper],
  );

  /**
   * Sign out process
   */
  const signOut = React.useCallback(async () => {
    await progressWrapper(() => Auth.signOut());
  }, [progressWrapper]);

  /**
   * Init reset password process
   */
  const resetPassword = React.useCallback(
    async (username: string) => {
      await progressWrapper(() => Auth.forgotPassword(username));
    },
    [progressWrapper],
  );

  /**
   * Confirm reset password process
   */
  const confirmResetPassword = React.useCallback(
    async (username: string, code: string, password: string) => {
      await progressWrapper(() =>
        Auth.forgotPasswordSubmit(username, code, password),
      );
    },
    [progressWrapper],
  );

  /**
   * Complete new password (challenge: NEW_PASSWORD_REQUIRED)
   */
  const completeNewPassword = React.useCallback(
    async (password: string) => {
      const res = await progressWrapper(() =>
        Auth.completeNewPassword(user, password),
      );
      setRoute('authenticated');
      return res;
    },
    [progressWrapper, user],
  );

  // Checks if clientId is defined in the query and if user is already authenticated
  React.useEffect(() => {
    const checkSession = async () => {
      try {
        await Auth.currentSession();
        setRoute('authenticated');
      } catch (err) {
        setRoute('signIn');
      }
    };

    // Check if app defined the clientId
    if (!query.clientId) {
      navigate('unauthorized', { state: { error: 'no_client_id' } });
      return;
    }

    Auth.configure({ userPoolWebClientId: query.clientId });
    checkSession();
  }, [setRoute, navigate, query.clientId]);

  /**
   * Prepare the auth value
   */
  const providerValue: AuthContext = React.useMemo(
    () => ({
      route,
      inProgress,
      error,
      user: {},
      signIn,
      signOut,
      resetPassword,
      confirmResetPassword,
      completeNewPassword,
      // handle internal states
      setRoute,
    }),
    [
      route,
      inProgress,
      error,
      signIn,
      signOut,
      resetPassword,
      confirmResetPassword,
      completeNewPassword,
    ],
  );

  return (
    <authContext.Provider value={providerValue}>
      {children}
    </authContext.Provider>
  );
};

export default AuthenticationProvider;
