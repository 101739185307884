import { Text, Heading, Image, Surface } from '@quality24/design-system';
import React from 'react';
import classNames from 'classnames';

import styles from './LoginTemplate.module.scss';

export interface Props {
  copyright: string;
  logo: string;
  children: React.ReactNode;
}

const DesktopTemplate: React.FunctionComponent<Props> = ({
  copyright,
  logo,
  children,
}) => (
  <div className={classNames(styles.wrapper, 'row')}>
    <div className="col-12 col-lg-6">
      <div className={styles['logo-section']}>
        <div>
          <Image
            src={logo}
            alt="Quality24 - Logo"
            className="mb-md-5"
            width={179}
          />

          <Heading as="h1" size="md">
            A maior plataforma de
            <br />
            <strong style={{ fontSize: '1.15em' }}>
              gestão da comunicação
            </strong>
            <br />
            de pacientes e colaboradores em tempo real
          </Heading>
        </div>

        <Text size="sm2" weight="light">
          {copyright}
        </Text>
      </div>
    </div>

    <main className="col-12 col-lg-6">
      <Surface className={styles.surface}>{children}</Surface>
    </main>
  </div>
);

export default DesktopTemplate;
