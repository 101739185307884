import * as React from 'react';
import { Text } from '@quality24/design-system';

import ForgotPasswordForm, {
  ForgotPasswordFormValues,
} from '../../components/organisms/ForgotPasswordForm';

export interface Props {
  inProgress: boolean;
  initialValues: ForgotPasswordFormValues;
  onSubmit: (values: ForgotPasswordFormValues) => Promise<void>;
  onGoBack: () => void;
}

const SendConfirmationSection: React.FunctionComponent<Props> = ({
  inProgress,
  initialValues,
  onSubmit,
  onGoBack,
}) => (
  <>
    <Text className="mb-3" weight="light">
      Preencha seu email abaixo e um <strong>código de confirmação</strong> será
      enviado para que você possa redefinir sua senha.
    </Text>

    <ForgotPasswordForm
      initialValues={initialValues}
      loading={inProgress}
      onSubmit={onSubmit}
      onGoBack={onGoBack}
    />
  </>
);

export default SendConfirmationSection;
