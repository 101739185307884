import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from '@quality24/design-system';

import MobileTemplate from './MobileTemplate';
import DesktopTemplate from './DesktopTemplate';
import logo from '../../../media/q24_logo_white.png';
import styles from './LoginTemplate.module.scss';

export interface Props {
  children: React.ReactNode;
}

const LoginTemplate: React.FunctionComponent<Props> = ({ children }) => {
  const { theme } = useTheme();
  const isDesktop = useMediaQuery({
    query: `(min-width: ${theme.breakpoints.lg})`,
  });

  const Template = isDesktop ? DesktopTemplate : MobileTemplate;

  const copyright =
    '© Quality24 Tecnologia 2022. Todos os direitos reservados.';

  return (
    <div className={styles.background}>
      <Template logo={logo} copyright={copyright}>
        {children}
      </Template>
    </div>
  );
};

export default LoginTemplate;
