import React from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { useAuth } from './contexts/auth/useAuth';
import { getRedirectUri } from './utils/url';

import { runtimeConfig } from './config';
import LoginTemplate from './components/templates/LoginTemplate';
import LoginPage from './pages/LoginPage';
import LogoutPage from './pages/LogoutPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import UnauthorizedPage from './pages/UnauthorizedPage';

const App: React.FunctionComponent = () => {
  const location = useLocation();
  const { route } = useAuth();

  // Redirect user upon authentication
  React.useEffect(() => {
    if (route === 'authenticated' && !location.pathname.includes('logout')) {
      const redirectUri = getRedirectUri(
        location.search,
        runtimeConfig.REDIRECT_URI || process.env.REACT_APP_REDIRECT_URI,
      );

      if (redirectUri) {
        // eslint-disable-next-line no-console
        console.info('User authenticated, redirecting to ', redirectUri);
        if (!process.env.REACT_APP_PREVENT_REDIRECT) {
          window.location.replace(redirectUri);
        }
      }
    }
  });

  return (
    <LoginTemplate>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/logout" element={<LogoutPage />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />

        <Route path="/unauthorized" element={<UnauthorizedPage />} />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </LoginTemplate>
  );
};

export default App;
