import * as React from 'react';
import classNames from 'classnames';
import {
  BorderRadiusProps,
  ColorStyleProps,
  SpacingProps,
} from '../../../@types/theme';

import styles from './Surface.module.scss';

export interface Props
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'color'> {
  backgroundColor?: ColorStyleProps;
  color?: ColorStyleProps;
  padding?: SpacingProps;
  borderRadius?: BorderRadiusProps;
  children?: React.ReactNode;
}

export const Surface: React.FunctionComponent<Props> = ({
  className,
  backgroundColor,
  color,
  padding,
  borderRadius,
  children,
  ...htmlElementProps
}) => {
  const cls = classNames(
    styles.surface,
    {
      [`bg-${backgroundColor}`]: backgroundColor,
      [`text-${color}`]: color,
      [`rounded-${borderRadius}`]: borderRadius,
      [`p-${padding}`]: padding,
    },
    className,
  );

  return (
    <div className={cls} {...htmlElementProps}>
      {children}
    </div>
  );
};

export default Surface;
