import * as React from 'react';
import { Button, Text } from '@quality24/design-system';
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons';

import { ForgotPasswordFormValues } from '../../components/organisms/ForgotPasswordForm';
import ConfirmForgotPasswordForm, {
  ConfirmForgotPasswordFormValues,
} from '../../components/organisms/ConfirmForgotPasswordForm';

export interface Props {
  username: string;
  inProgress: boolean;
  initialValues: ConfirmForgotPasswordFormValues;
  onSubmit: (values: ConfirmForgotPasswordFormValues) => Promise<void>;
  onResend: (values: ForgotPasswordFormValues) => Promise<void>;
  onGoBack: () => void;
}

const CodeValidationSection: React.FunctionComponent<Props> = ({
  username,
  inProgress,
  initialValues,
  onSubmit,
  onResend,
  onGoBack,
}) => (
  <>
    <Text className="mb-3" weight="light">
      Enviamos um <strong>código de verificação</strong>, verifique seu e-mail.
      Caso não tenha recebido, verifique sua caixa de spam ou solicite o
      reenvio.
    </Text>

    <Button
      variant="outline"
      icon={faEnvelope}
      className="mb-4"
      ellipsize
      disabled
    >
      {username}
    </Button>

    <ConfirmForgotPasswordForm
      initialValues={initialValues}
      loading={inProgress}
      onSubmit={onSubmit}
      onResend={onResend}
      onGoBack={onGoBack}
    />
  </>
);

export default CodeValidationSection;
