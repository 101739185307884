import merge from 'deepmerge';
import { Theme, ColorStyleProps } from '../@types/theme';
import theme from '../styles/theme';

export interface ParseColorOptions {
  theme?: Theme;
}

export const parseChartColors = (
  colors: ColorStyleProps[],
  options?: ParseColorOptions,
): Array<string> =>
  colors.map((color) =>
    color.charAt(0) === '#' ? color : (options?.theme ?? theme).color[color],
  ) as string[];

export const parseColor = (
  color: ColorStyleProps,
  options?: ParseColorOptions,
): string =>
  color.charAt(0) === '#'
    ? color
    : ((options?.theme ?? theme).color[color] as string);

type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};

/**
 * Merges a partial Theme object with the default theme
 * @param theme
 * @returns
 */
export const createTheme = (userTheme?: RecursivePartial<Theme>): Theme => {
  if (!userTheme) return theme;
  return merge(theme, userTheme) as Theme;
};

//
// SASS/CSS variables utils

/**
 * Converts the Theme object to SASS variables into an array with each entry in the format:
 * --q24-color-grayLight1: #ffffff;
 *
 * This array should be used to extend the application theme:
 * injectCss(`
 * :root {
 * ${convertThemeVars(theme).join('\n')}
 * }
 * `);
 *
 * @note this is a recursive function and might take long to process if object has many nested objects.
 * @param theme
 * @returns
 */
export const convertThemeVars = (
  themeObj: Record<string, unknown>,
  isTop = true,
): Array<string> => {
  const entries: Array<string> = [];
  Object.entries(themeObj).forEach(([k, value]) => {
    const mainKey = isTop ? `--q24-${k}` : k;
    if (typeof value === 'string') {
      entries.push(`${mainKey}: ${value};`);
      return;
    }

    const flatEntries = convertThemeVars(
      themeObj[k] as Record<string, unknown>,
      false,
    ).map<string>((v) => `${mainKey}-${v}`);
    entries.push(...flatEntries);
  });

  return entries;
};

/**
 * Converts a color name into its CSS variable name
 * @param colorName
 * @returns
 */
export const convertColorToCSSVars = (colorName: ColorStyleProps) => {
  return `--q24-color-${colorName}`;
};

/**
 * Get an array of SASS variable names from the Theme object in the following format:
 * ['primary', 'secondary', 'tertiary', 'chart-0', 'chart-1', 'pain-none', 'pain-mild']
 *
 * @note this is a recursive function and might take long to process if object has many nested objects.
 * @param theme
 * @returns string[]
 */
export const getThemeVarNames = (
  themeObj: Record<string, unknown>,
): Array<string> => {
  const entries: Array<string> = [];
  Object.entries(themeObj).forEach(([mainKey, value]) => {
    if (typeof value === 'string') {
      entries.push(`${mainKey}`);
      return;
    }

    const flatEntries = getThemeVarNames(
      themeObj[mainKey] as Record<string, unknown>,
    ).map<string>((v) => `${mainKey}-${v}`);
    entries.push(...flatEntries);
  });

  return entries;
};
