import * as React from 'react';
import NewPasswordForm, {
  NewPasswordFormValues,
} from '../../components/organisms/NewPasswordForm';

export interface Props {
  inProgress: boolean;
  onPasswordChange: (values: NewPasswordFormValues) => Promise<void>;
}

const NewPasswordSection: React.FunctionComponent<Props> = ({
  inProgress,
  onPasswordChange,
}) => <NewPasswordForm loading={inProgress} onSubmit={onPasswordChange} />;

export default NewPasswordSection;
