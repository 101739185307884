import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Alert, SpinnerOverlay } from '@quality24/design-system';

import { useAuth } from '../../contexts/auth/useAuth';
import { parseQuery, stringifyQuery } from '../../utils/url';

import type { ForgotPasswordFormValues } from '../../components/organisms/ForgotPasswordForm';
import type { ConfirmForgotPasswordFormValues } from '../../components/organisms/ConfirmForgotPasswordForm';
import type { NewPasswordFormValues } from '../../components/organisms/NewPasswordForm';

import SendConfirmationSection from './SendConfirmationSection';
import CodeValidationSection from './CodeValidationSection';
import NewPasswordSection from './NewPasswordSection';

const ForgotPasswordPage: React.FunctionComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    route,
    inProgress,
    error,
    resetPassword,
    confirmResetPassword,
    setRoute,
  } = useAuth();

  // Get the query string object
  const query = parseQuery(location.search);

  // Set initial page route
  React.useEffect(() => {
    setRoute((old) => {
      if (old === 'confirmResetPassword') return old;
      return query.verification_code ? 'confirmResetPassword' : 'resetPassword';
    });
  }, [query, setRoute]);

  /**
   * Sends verification to user
   */
  const handleForgotPasswordSubmit = React.useCallback(
    async ({ email }: ForgotPasswordFormValues) => {
      try {
        await resetPassword(email);
        location.search = stringifyQuery({ ...query, username: email });
        setRoute('confirmResetPassword');
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Failed to send forgot password: ', err);
      }
    },
    [resetPassword, setRoute, location, query],
  );

  /**
   * Sends verification to user
   */
  const handleResendConfirmation = React.useCallback(
    async ({ email }: ForgotPasswordFormValues) => {
      try {
        await resetPassword(email);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Failed to resend confirmation: ', err);
      }
    },
    [resetPassword],
  );

  /**
   * Changes state after verification code input
   */
  const handleVerifyCode = React.useCallback(
    async ({ confirmationCode }: ConfirmForgotPasswordFormValues) => {
      navigate(
        `/forgot-password?${stringifyQuery({
          ...query,
          verification_code: confirmationCode,
        })}`,
      );
    },
    [navigate, query],
  );

  /**
   * Resets user password to new value
   */
  const handleConfirmResetPasswordSubmit = React.useCallback(
    async ({ newPassword }: NewPasswordFormValues) => {
      try {
        await confirmResetPassword(
          query.username as string,
          query.verification_code as string,
          newPassword,
        );
        navigate(`/login?${stringifyQuery(query)}`);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Failed to send confirm forgot password: ', err);
      }
    },
    [confirmResetPassword, query, navigate],
  );

  /**
   * Resets user password to new value
   */
  const goBackToLogin = React.useCallback(() => {
    navigate(`/login?${stringifyQuery(query)}`);
  }, [navigate, query]);

  return (
    <>
      {error && (
        <Alert className="mb-3" variant="danger">
          {error}
        </Alert>
      )}
      {inProgress && <SpinnerOverlay />}

      {route === 'resetPassword' && (
        <SendConfirmationSection
          inProgress={inProgress}
          initialValues={{
            email: (query.username as string) || '',
          }}
          onSubmit={handleForgotPasswordSubmit}
          onGoBack={goBackToLogin}
        />
      )}

      {route === 'confirmResetPassword' && !query.verification_code && (
        <CodeValidationSection
          inProgress={inProgress}
          username={query.username as string}
          initialValues={{
            email: query.username as string,
            confirmationCode: '',
          }}
          onSubmit={handleVerifyCode}
          onResend={handleResendConfirmation}
          onGoBack={goBackToLogin}
        />
      )}

      {route === 'confirmResetPassword' && query.verification_code && (
        <NewPasswordSection
          inProgress={inProgress}
          username={query.username as string}
          onSubmit={handleConfirmResetPasswordSubmit}
        />
      )}
    </>
  );
};

export default ForgotPasswordPage;
