import * as React from 'react';
import classNames from 'classnames';
import Spinner from '../../atoms/Spinner';

import styles from './SpinnerOverlay.module.scss';

export interface Props extends React.HTMLAttributes<HTMLElement> {
  className?: string;
}

const SpinnerOverlay: React.FunctionComponent<Props> = ({
  className,
  ...htmlElementProps
}) => (
  <div className={classNames(styles.overlay, className)} {...htmlElementProps}>
    <Spinner />
  </div>
);

export default SpinnerOverlay;
